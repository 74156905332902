@maxWidth: 500Px;
@navHeight: 117px;
@backNavHeight: 135px;
@bigContainerHeight: 504px;
@bgcolor: #f1f1f1;
@textHotColor: #FF771D;
@textCoolColor: #FFAB64;
@fontTitleColor: #333333;
@fontTitleSize: 32px;
@fontSubTilteColor: #666666;
@fontSubTitleSize: 28px;
@fontMidTitleSize: 30px;
@fontMidColor: #5E5D5D;
@fontSmallTitleSize: 26px;
@boxRadius: 16px;
@buttonRadius: 10px;


.linear-gradient {
  background: linear-gradient(135deg, #FF821F, #FFC12B);
}

.button-linear-gradient {
  background-image: linear-gradient(to right, #ff9075, #ffb36f);
}


.bgGreyColor {
  background: @bgcolor;
}

.shadow {
  box-shadow: 0 0 8px #d9d9d9;
}

.shadow-sm {
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.1);
}



.text-ellipsis {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: keep-all;
}

.clearfix:after {
  content: "";
  /*内容设置为空！！！*/
  display: block;
  /*显示为块级元素！！！*/
  height: 0;
  visibility: hidden;
  clear: both;
}

.clearfix {
  /* 触发 hasLayout */
  zoom: 1;
}


// 需要指定其高度为 504px
.radianBigContainer {
  .linear-gradient();
  border-bottom-right-radius: 400px 210px;
  border-bottom-left-radius: 340px 120px;
}

.radianMiddleContainer {
  .linear-gradient();
  height: @backNavHeight;
  border-bottom-right-radius: 340px 210px;
  border-bottom-left-radius: 400px 210px;
}

.radianMinContainer {
  .linear-gradient();
  height: @backNavHeight;
  background-size: 100% 136px;
  background-repeat: no-repeat;
  border-bottom-right-radius: 50% 58px;
  border-bottom-left-radius: 50% 58px;
}
