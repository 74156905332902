@import '~src/theme';
.nav {
    width: 100%;
    height: @navHeight;
    position: fixed;
    bottom: 0;
    z-index: 99;
}

.flexBox {
    height: 100%;
    border-radius: 14px 14px 0 0;
    background: #fff;
    .shadow();
}

.navItem {
    text-align: left;
}
.nav-a {
    display: block;
}
.imgContainer {
    height: 31px;
    text-align: center;
    img {
        display: block;
        height: 100%;
    }
    :global(.activeImg) {
        display: none;
    }
}

:global(.active) {
    img {
        display: none;
    }
    :global(.activeImg) {
        display: inline-block;
    }
}

.navText {
    font-size: 20px;
    font-weight: 500;
    color: #808080;
    margin-top: 10px;
}
.nav :global(.active) .navText{
    color: #ff866b;
}

@hd: 2px;@brand-primary: #ffad64;