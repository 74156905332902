@import "~nprogress/nprogress.css";
@import "~animate.css";
* {
    box-sizing: border-box;
}
body {
    background-color: #fff !important;
}
#root {
    margin: 0 auto;
    width: 100%;
    min-height: 100vh;
    overflow: hidden;
}


p {
    margin: 0;
}

.am-wingblank-lg {
    margin-left: 24px !important;
    margin-right: 24px !important;
}

.text-ellipsis {
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    word-break: keep-all;
}

.am-whitespace-sm {
    height: 16px !important;
}
.am-whitespace-lg {
    height: 40px !important;
}
.am-whitespace-md {
    height: 30px !important;
}

//#nprogress  {
//    .bar {
//        background: #ff866b !important; //自定义颜色
//    }
//    .spinner-icon {
//        border-top-color: #ffad64;
//        border-left-color: #ffad64;
//    }
//}

.notData {
    padding-top: 100px;
    img {
        width: 258px;
        height: 258px;
    }
    span {
        margin-top: 30px;
        font-size: 24px;
        color: #999999;
    }
}

.insureFormPicker {
    .am-picker-col-item {
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@hd: 2px;@brand-primary: #ffad64;