@import "~src/theme.less";
.back-fixed {
  position: fixed;
  top: 0;
  left: 0;

}
.backBox {
  height: @backNavHeight;
}
.backBg {
  .radianMinContainer();
}
.back {
  width: 100%;
  padding: 35px 20px;
  font-size: 32px;
  font-weight: 500;
  color: #FFFFFF;
  display: flex;
}

.backIcon {
  flex: none !important;
}


.fangkuai {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  height: 88px;
  line-height: 88px;
  .linear-gradient();
  width: 100%;
  border-radius: initial;
}
@hd: 2px;@brand-primary: #ffad64;