.footer {
    padding: 101px 0 213px;
    font-size: 18px;
    color: #999999;
    text-align: center;
    a {
        color: #999999;
    }
    p:last-child {
        margin-top: 14px;
    }
}

@hd: 2px;@brand-primary: #ffad64;